@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Lato:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Heebo);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'SimplonBP';
  src: url('http://personas.bioticasostenible.com/fuentes/SimplonBP-Bold.otf');
  /* src: url('http://personastest.bioticasostenible.com/fuentes/SimplonBP-Bold.otf'); */
  font-weight: bold;
	font-style: normal;
}

@font-face {
  font-family: 'SimplonBP';
  src: url('http://personas.bioticasostenible.com/fuentes/SimplonBP-Medium.otf');
  /* src: url('http://personastest.bioticasostenible.com/fuentes/SimplonBP-Medium.otf'); */
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'SimplonBPRegular';
  src: url('http://personas.bioticasostenible.com/fuentes/SimplonBPRegular.otf');
  /* src: url('http://personastest.bioticasostenible.com/fuentes/SimplonBPRegular.otf'); */
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'SimplonBPMono';
  src: url('http://personas.bioticasostenible.com/fuentes/SimplonBPMono-Regular.otf');
  /* src: url('http://personastest.bioticasostenible.com/fuentes/SimplonBPMono-Regular.otf'); */
  font-weight: normal;
	font-style: normal;
}


body{
  font-family: 'Lato', sans-serif;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

.switch-wrapper{
  overflow: hidden;
}

.App{
  width: 100vw;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: 15vw 1fr ;
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    "header header"
    "sidebar main";
}

.App.quest-content{
  grid-template-areas: 
    "header header"
    "main main";
}

header{
  grid-area: header;
}
aside{
  grid-area: sidebar;
}

main{
  grid-area: main;
}


/*Header*/
header{
  min-height: 7vmin;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  background-color: rgb(45,113,138);

  display: grid;
  grid-template-columns: 13vw 1fr ;
  z-index: 5;
  color: white;
}

header > div.aside-left{
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items : center;
}

header > div.aside-left > span.fa{
  /* color: #B3CCE4; */
}


header > div.aside-left img{
  height: 3vmin;
  margin-left: 1rem;;
}

header > div.aside-rigth{
  display: flex;
  justify-content: flex-end;
  padding: 0.3rem 1rem;
  align-items: center;
}

header > div.aside-rigth .content-avatar{
  display: flex;
  align-items: center;
}

.content-avatar span:first-child{
  /* font-family: Montserrat; */
  font-family: SimplonBP;
  /* font-family: SimplonBPRegular; */
  /* font-family: SimplonBPMono; */
  /* color: #398599; */
  font-size: 14pt;
  font-weight: bold;
}
.content-avatar span.img-avatar{
  width: 30px;
  height: 30px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  border-radius: 25px;
  margin-left: 0.7rem;
}

/*end Header*/


/*Menu*/

aside{
  display: flex;
  flex-direction: column;
  /* font-family: Heebo; */
  /* font-family: SimplonBP; */
  font-family: SimplonBPRegular;
  /* font-family: SimplonBPMono; */
  z-index: 3;
  /* background-color: rgb(224, 224, 224);
  color: rgb(46,114,138); */
  background-color: #F2F2F2;
  color: rgb(46,114,138);
  /* color: white; */
}

aside > nav{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 2rem 1.3rem;
}

aside > nav span.caption{
  /* color: #C0BEC5; */
  margin-bottom: 0.3rem;
}

aside > nav > section.section-menu:first-child{
  margin-bottom: 2rem;
}

aside > nav > section.section-menu{
  margin-bottom: 2rem;
}
aside > nav span.caption{
  margin-bottom: 1rem;
  display: block;
  text-align: left;
  font-size: 15pt;
}
aside > nav a{
  text-decoration: none;
  /* color: #474857; */
  color: rgb(46,114,138);
  /* color: white; */
  display: block;
  margin-bottom: 0.3rem;
  font-size: 14pt;
}

aside > nav span.caption ~ a{
  margin-left: 0.7rem;
  margin-bottom: 1rem;
}

aside > nav a .fa{
  margin-right: 0.7rem;
  font-size: 13pt;
  /* color: #C0BEC5; */
  /* color: rgb(46,114,138); */
}
aside > nav a.active > span:last-child{
  /* color: #398599; */
  margin-right: 0.9rem;
  font-weight: bold;
}
aside > nav a.active .fa{
  /* display: none; */
}
/* 
aside > nav a.active > .fa{
  color: #7887C8;
} */

aside > footer{
  box-shadow: 0 -1px 0px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
  flex-shrink: 1;
  height: 50px;
  /* color: #C0BEC5; */
  /* color: rgba(255,255,255,0.9); */
  color: rgb(46, 114, 138, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*end Menu*/


/*main*/
main{
  overflow-y: scroll;
  z-index: 1;
  color: #474857;
  /* color: #C0BEC5; */
}


main h1 {
  font-size: 22pt;
  /* color: #5C65AD; */
  color: #398599;
}
main h2 {
  font-size: 20pt;
  /* color: #5C65AD; */
  color: #398599;
}
main h3 {
  font-size: 18pt;
}
main h4 {
  font-size: 16pt;
}
main h5 {
  font-size: 14pt;
}
main h6 {
  font-size: 12pt;
}

.main-content{
  padding: 5vmin;
  box-sizing: border-box;
}

.section-main{
  margin-bottom: 5vmin;
}

/*end Main*/



.content-login{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
    
  }
  
  .content-login .brand-content{
    background-position: top;
    background-size: cover;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  
  .content-login .brand-content > div{
    width: 100%;
    height: 100%;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .content-login .brand-content img{
    max-height: 10vmin;
    width: auto;
  }
  
  .content-login .form-content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
  
  .content-login .form-content > div{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 2rem;
    width: 75%;
    box-sizing: border-box;
  }
  .content-login .form-content .form-section h2{
    color : #398599; 
    box-sizing: border-box;
  }
  
  .content-login .form-section{
    box-sizing: border-box;
    position: relative;
  }
  
  
  .content-login .form-section input{
    border: none;
    width: 100%;
    padding: 0.5rem 2rem;
    box-sizing: border-box;
    font-size: 16pt;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    color : #398599; 
  }
  
  .content-login .form-section > span{
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: rgba(51, 51, 51, 0.5);
    font-size: 14pt;
  }
  
  .content-login .form-content > div button{
    padding: 0.7rem 1rem;
    font-size: 14pt;
    border-radius: 40px;
    width: 200px;
    color: white;
    margin: auto;
    display: block;
    margin-top: 3rem;
    border: unset;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-position: left;
  }
  
  
.content-retrospective{
    padding: 1rem;
}

.content-retrospective h2{
    margin: 0;
    margin-bottom: 2rem;
}

.content-comments{
    display: grid;
    grid-template-columns: 1rf;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.content-comments > div{
    padding: 1rem;
    background-color: #F2F2F2;
}
/* .content-breathing-tools */

.content-breathing-tools{
    padding: 1rem;
    box-sizing: border-box;
}

.content-breathing-tools h2{
    margin: 0px;
    margin-bottom: 1rem;
}

.content-breathing-tools .content-tools{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

.content-breathing-tools .content-tools > div{
    padding: 1rem;
    box-sizing: border-box;
    background-color: #F2F2F2;
}

.content-breathing-tools .content-tools > div h4{
    margin: 0px;
}


.content-breathing-tools .content-main-tool{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.content-breathing-tools .content-main-tool .breath-item-current{
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: #F2F2F2;
}

.content-breathing-tools .content-main-tool .breath-item-current > div:first-child{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.content-breathing-tools .content-main-tool .breath-item-current > div:last-child{
    text-align: right;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.content-breathing-tools .content-main-tool .breath-item-current > div > div{
    padding: 1rem;
    box-sizing: border-box;
}


.content-breathing-tools .content-main-tool .breath-item-current > div > div:last-child > span{
    display: flex;
    clear: both;
    margin-bottom: 0.1rem;
    justify-content: space-between;
}

.content-breathing-tools .content-main-tool .breath-item-current h4,
.content-breathing-tools .content-main-tool .breath-item-current p{
    margin: 0;
}
/* .content-definicion */

.content-definicion {
  padding: 1rem;
  box-sizing: border-box;
}
.content-definicion h2 {
  margin: 0;
  margin-bottom: 1rem;
}
.content-definicion > .main-definicion {
  display: grid;
  grid-template-columns: 1fr 40%;
  padding: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.content-definicion > .main-definicion h3 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 16pt;
}

.content-definicion > .main-definicion h4 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 14pt;
}

.content-definicion > .main-definicion .definition-description-content {
  margin-bottom: 1rem;
}

.content-definicion .definition-steps-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.content-definicion .definition-steps-content > div {
  padding-left: 0.5rem;
  box-sizing: border-box;
}

.content-definicion > .main-definicion p {
  margin: 0;
}

.content-definicion .main-definicion > .content-left {
  position: relative;
}
.content-definicion .main-definicion > .content-left .fade-feedback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 114, 138, 0.1);
  border-radius: 10px;
}

.content-definicion .main-definicion > .content-left > div:last-child {
  z-index: 100;
}

.content-definicion .content-callback .callback {
  margin: auto;
  display: block;
  padding: 0.7rem 1rem;
  background: rgba(46, 114, 138, 0.8);
  font-family: Montserrat;
  font-size: 11pt;
  border: unset;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
}

.content-definicion .content-breath-count {
  margin-bottom: 1rem;
  text-align: right;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-definicion .content-breath-count > span {
  position: absolute;
  top: 0;
  right: 0;
  display: inline;
  color: white;
  background: rgba(46, 114, 138, 0.8);
  padding: 1rem;
  font-size: 18pt;
  border-radius: 5px;
}

.content-definicion .content-breath-count > span > small {
  font-size: 10pt;
}

.content-breath-indicator {
  width: 200px;
  /* height: 250px; */
  position: relative;
  display: flex;
  flex-direction: column;
}

.content-breath-indicator > div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-breath-indicator .breath-time {
  font-size: 30pt;
  line-height: 0.9;
}

.content-breath-indicator .breath-time small {
  font-size: 10pt;
}
.content-breath-indicator .breath-action {
  font-size: 10pt;
}


.content-breath-indicator svg {
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  position: relative;
}


.content-breath-indicator svg.breath[act='IN'] {
  -webkit-animation: BreathIn ease-out forwards;
          animation: BreathIn ease-out forwards;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}
.content-breath-indicator svg.breath[act='OUT'] {
  animation: BreathIn ease-out reverse forwards;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.content-breath-indicator svg.breath[act='AFTER-IN'] {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.content-breath-indicator svg.breath[act='AFTER-OUT'] {
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}


.content-breath-indicator svg path {
  -webkit-transition: 1s d;
  transition: 1s d;
}

.content-breath-init{
  position: absolute;
  z-index: 500;
  background-color: rgba(46, 114, 138, 0.5);
  /* background-color: rgba(46, 114, 138, 1); */
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-breath-init > div > select{
  border: unset;
  padding: 6px 5px 6px 7px;
  border-radius: 5px;
}

.content-breath-init > div {
  display: flex;
}

.content-breath-init > div > span{
  
  display: inline;
  background: white;
  color: rgb(46, 114, 138);
  padding: 5px 7px;
  /* font-size: 18pt; */
  border-radius: 5px;
  margin-left: 5px;
}

@-webkit-keyframes BreathIn {
  0% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes BreathIn {
  0% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* content-feedback-detail */

.content-feedback-detail {
    padding: 1rem;
  }
  
  .content-feedback-detail h2 {
    margin-bottom: 0;
  }
  
  .content-feedback-detail > small {
    display: block;
  }
  .content-feedback-detail > small + span {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .content-feedback-detail > div:not(:last-child) {
    width: 100%;
    margin-bottom: 1.2rem;
    position: relative;
  }
  
  .content-feedback-detail textarea {
    width: 100%;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid white;
    height: 130px;
    box-sizing: border-box;
    font-family: Heebo;
    line-height: 1.2;
    font-size: 11pt;
    z-index: 2;
    position: relative;
    color: #333;
  }
  .content-feedback-detail textarea.tEmpty {
    background-color: rgba(255, 255, 255, 0);
  }
  
  .content-feedback-detail textarea + span {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid white;
    height: 130px;
    box-sizing: border-box;
    font-family: Heebo;
    line-height: 1.2;
    font-size: 11pt;
    opacity: 0;
    color: #6d6d6d;
  }
  
  .content-feedback-detail textarea.tEmpty + span {
    opacity: 1;
  }
  
  .content-feedback-detail button {
    margin: auto;
    display: block;
    padding: 0.7rem 1rem;
    background: white;
    font-family: Montserrat;
    font-size: 11pt;
    border: unset;
  }
  .content-feedback-detail button.disable {
    background: rgb(255, 255, 255, 0.7);
    color: #6d6d6d;
  }
  
  .content-feedback-detail > .content-description{
      margin-bottom: 1rem;
      display: block;
  }

  .content-feedback-detail .content-feedback-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .content-feedback-detail .content-feedback-options .content-feedback-options-item .check,
  .content-feedback-detail .content-feedback-options .content-feedback-options-item .check-dash {
    display: none;
  }
  
  .content-feedback-detail .content-feedback-options .content-feedback-options-item.active .check,
  .content-feedback-detail .content-feedback-options .content-feedback-options-item.active .check-dash {
    display: inherit;
  }

  .content-feedback-options-item .option-icon-check{
      display: block;
      width: 30px;
      height: 30px;
      margin: auto ;
  }
  
  .content-feedback-detail .content-feedback-options-item {
    font-size: 10pt;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
/* .content-scheduler */
.content-scheduler {
  padding: 1rem;
  box-sizing: border-box;
}

.content-scheduler h2,
.content-scheduler h3,
.content-scheduler h4,
.content-scheduler p {
  margin: 0;
}

.content-scheduler > div.content-main-scheduler {
  display: grid;
  grid-template-columns: 1fr 60%;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
}

.content-scheduler div.content-main-scheduler > div {
  padding: 1rem;
  box-sizing: border-box;
}

.content-scheduler div.content-proyects {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.content-scheduler div.content-proyects > .item-proyect {
  background-color: #f2f2f2;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.content-scheduler div.content-proyects > .item-proyect h4 {
  width: 100%;
  text-align: left;
}
.content-scheduler div.content-proyects > .item-proyect {
  text-align: right;
}

.content-scheduler div.content-proyects > .item-proyect > span {
  color: rgb(46, 114, 138);
}

.content-scheduler div.content-proyects > hr {
  width: 100%;
}

.content-scheduler div.content-task {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.content-scheduler div.content-task > .content-task-title * {
  color: rgb(46, 114, 138);
  /* border-top-color: rgb(46,114,138); */
}

.content-scheduler div.content-task > .item-task {
  background-color: #f2f2f2;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
}
.content-scheduler div.content-task > .item-task > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 14pt;
  color: rgb(46, 114, 138);
}

.content-scheduler div.content-task > .item-task > div > span:first-child {
  font-size: 12pt;
}

.content-scheduler div.content-task > p{
  text-align: center;
}

.content-scheduler div.item-proyect span.fa-remove,
.content-scheduler div.content-task span.fa-remove {
  color: #474857;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
  cursor: pointer;
}

.content-scheduler .content-callback-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.content-scheduler .content-callback-title > *:last-child {
  cursor: pointer;
}

.content-scheduler .content-callback-title > * {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #f2f2f2;
  width: 60vmin;
  padding: 1rem 2rem;
  z-index: 2;
}

.modal-content h4 {
  margin-bottom: 1rem;
}

.modal-content > div:not(.input-content) {
  display: grid;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.modal-content div.input-content {
  display: flex;
  flex-direction: column;
}

.modal-content div.input-content > label {
  margin-bottom: 5px;
}

.modal-content div.input-content > input {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
}

.modal-content div.input-content > select {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
  box-sizing: border-box;
}

.modal-content div.input-content > textarea {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
  box-sizing: border-box;
}

.modal-content div.input-content > input:hover {
  border: unset;
}

.input-content > .content-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.input-content > .content-options > label{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.input-content > .content-options > label > span{
  margin-bottom: 5px;
}
.input-content > .content-options > label > input{
  margin: auto;
}


.modal-content > div:last-child > span {
  margin: auto;
  display: block;
  padding: 0.7rem 1rem;
  background: rgba(46, 114, 138, 0.8);
  font-family: Montserrat;
  font-size: 11pt;
  border: unset;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
}

.modal-fade {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.alert-confirm {
  background-color: rgb(46, 114, 138);
  color: white;
  padding: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.alert-confirm p {
  margin-bottom: 1rem;
}

.alert-confirm > div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.content-task-notification{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
}

.content-task-notification > div.item-notification{
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
}
.item-notification.notification-danger{
  background-color: #e65100; 
  color: white;
}
.item-notification.notification-warning{
  background-color: #ffa000; 
  color: white;
}
.item-notification.notification-success{
  background-color: #2d718a; 
  color: white;
}
.content-task-notification > div.item-notification > span{
  position: absolute;
  top: 5px;
  right: 5px;
}
/* content-profile */

.content-profile {
  display: grid;
  grid-template-columns: 1fr 30%;
  padding: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

/* .content-profile .content-definition{
    height: calc(100vh - 7vmin - 2.2rem);
    overflow-y: scroll;
} */

.content-profile .content-avatar-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-start; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.content-profile .content-avatar-profile > p{
    align-self: flex-start;
}

.content-profile .content-definition h2 {
  margin: 0px;
}

.content-profile .content-definition h3 {
  font-size: 14pt;
}

.content-profile .content-definition p,
.content-profile .content-definition h3 {
  margin: 0px;
}

.content-profile .content-definition .content-graph > div {
  padding: 1rem;
  box-sizing: border-box;
}

.content-profile .content-skills > h2{
    margin-bottom: 0.7rem;
}

.content-powers {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.content-powers > div {
  padding: 1rem;
  background-color: #f2f2f2;
  box-sizing: border-box;
}
.content-profile .content-powers > div h3 {
  margin-bottom: 0.7rem;
}

/* .content-powers{ */

