
.content-login{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
    
  }
  
  .content-login .brand-content{
    background-position: top;
    background-size: cover;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  
  .content-login .brand-content > div{
    width: 100%;
    height: 100%;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .content-login .brand-content img{
    max-height: 10vmin;
    width: auto;
  }
  
  .content-login .form-content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
  
  .content-login .form-content > div{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 2rem;
    width: 75%;
    box-sizing: border-box;
  }
  .content-login .form-content .form-section h2{
    color : #398599; 
    box-sizing: border-box;
  }
  
  .content-login .form-section{
    box-sizing: border-box;
    position: relative;
  }
  
  
  .content-login .form-section input{
    border: none;
    width: 100%;
    padding: 0.5rem 2rem;
    box-sizing: border-box;
    font-size: 16pt;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    color : #398599; 
  }
  
  .content-login .form-section > span{
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: rgba(51, 51, 51, 0.5);
    font-size: 14pt;
  }
  
  .content-login .form-content > div button{
    padding: 0.7rem 1rem;
    font-size: 14pt;
    border-radius: 40px;
    width: 200px;
    color: white;
    margin: auto;
    display: block;
    margin-top: 3rem;
    border: unset;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-position: left;
  }
  
  