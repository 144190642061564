@import url('https://fonts.googleapis.com/css?family=Montserrat'); 
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Lato:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Heebo'); 


@font-face {
  font-family: 'SimplonBP';
  src: url('http://personas.bioticasostenible.com/fuentes/SimplonBP-Bold.otf');
  /* src: url('http://personastest.bioticasostenible.com/fuentes/SimplonBP-Bold.otf'); */
  font-weight: bold;
	font-style: normal;
}

@font-face {
  font-family: 'SimplonBP';
  src: url('http://personas.bioticasostenible.com/fuentes/SimplonBP-Medium.otf');
  /* src: url('http://personastest.bioticasostenible.com/fuentes/SimplonBP-Medium.otf'); */
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'SimplonBPRegular';
  src: url('http://personas.bioticasostenible.com/fuentes/SimplonBPRegular.otf');
  /* src: url('http://personastest.bioticasostenible.com/fuentes/SimplonBPRegular.otf'); */
  font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'SimplonBPMono';
  src: url('http://personas.bioticasostenible.com/fuentes/SimplonBPMono-Regular.otf');
  /* src: url('http://personastest.bioticasostenible.com/fuentes/SimplonBPMono-Regular.otf'); */
  font-weight: normal;
	font-style: normal;
}


body{
  font-family: 'Lato', sans-serif;
  overscroll-behavior: none;
}

.switch-wrapper{
  overflow: hidden;
}

.App{
  width: 100vw;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: 15vw 1fr ;
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    "header header"
    "sidebar main";
}

.App.quest-content{
  grid-template-areas: 
    "header header"
    "main main";
}

header{
  grid-area: header;
}
aside{
  grid-area: sidebar;
}

main{
  grid-area: main;
}


/*Header*/
header{
  min-height: 7vmin;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  background-color: rgb(45,113,138);

  display: grid;
  grid-template-columns: 13vw 1fr ;
  z-index: 5;
  color: white;
}

header > div.aside-left{
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items : center;
}

header > div.aside-left > span.fa{
  /* color: #B3CCE4; */
}


header > div.aside-left img{
  height: 3vmin;
  margin-left: 1rem;;
}

header > div.aside-rigth{
  display: flex;
  justify-content: flex-end;
  padding: 0.3rem 1rem;
  align-items: center;
}

header > div.aside-rigth .content-avatar{
  display: flex;
  align-items: center;
}

.content-avatar span:first-child{
  /* font-family: Montserrat; */
  font-family: SimplonBP;
  /* font-family: SimplonBPRegular; */
  /* font-family: SimplonBPMono; */
  /* color: #398599; */
  font-size: 14pt;
  font-weight: bold;
}
.content-avatar span.img-avatar{
  width: 30px;
  height: 30px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  border-radius: 25px;
  margin-left: 0.7rem;
}

/*end Header*/


/*Menu*/

aside{
  display: flex;
  flex-direction: column;
  /* font-family: Heebo; */
  /* font-family: SimplonBP; */
  font-family: SimplonBPRegular;
  /* font-family: SimplonBPMono; */
  z-index: 3;
  /* background-color: rgb(224, 224, 224);
  color: rgb(46,114,138); */
  background-color: #F2F2F2;
  color: rgb(46,114,138);
  /* color: white; */
}

aside > nav{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 2rem 1.3rem;
}

aside > nav span.caption{
  /* color: #C0BEC5; */
  margin-bottom: 0.3rem;
}

aside > nav > section.section-menu:first-child{
  margin-bottom: 2rem;
}

aside > nav > section.section-menu{
  margin-bottom: 2rem;
}
aside > nav span.caption{
  margin-bottom: 1rem;
  display: block;
  text-align: left;
  font-size: 15pt;
}
aside > nav a{
  text-decoration: none;
  /* color: #474857; */
  color: rgb(46,114,138);
  /* color: white; */
  display: block;
  margin-bottom: 0.3rem;
  font-size: 14pt;
}

aside > nav span.caption ~ a{
  margin-left: 0.7rem;
  margin-bottom: 1rem;
}

aside > nav a .fa{
  margin-right: 0.7rem;
  font-size: 13pt;
  /* color: #C0BEC5; */
  /* color: rgb(46,114,138); */
}
aside > nav a.active > span:last-child{
  /* color: #398599; */
  margin-right: 0.9rem;
  font-weight: bold;
}
aside > nav a.active .fa{
  /* display: none; */
}
/* 
aside > nav a.active > .fa{
  color: #7887C8;
} */

aside > footer{
  box-shadow: 0 -1px 0px rgba(0,0,0,0.12), 0 0px 0px rgba(0,0,0,0.24);
  flex-shrink: 1;
  height: 50px;
  /* color: #C0BEC5; */
  /* color: rgba(255,255,255,0.9); */
  color: rgb(46, 114, 138, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*end Menu*/


/*main*/
main{
  overflow-y: scroll;
  z-index: 1;
  color: #474857;
  /* color: #C0BEC5; */
}


main h1 {
  font-size: 22pt;
  /* color: #5C65AD; */
  color: #398599;
}
main h2 {
  font-size: 20pt;
  /* color: #5C65AD; */
  color: #398599;
}
main h3 {
  font-size: 18pt;
}
main h4 {
  font-size: 16pt;
}
main h5 {
  font-size: 14pt;
}
main h6 {
  font-size: 12pt;
}

.main-content{
  padding: 5vmin;
  box-sizing: border-box;
}

.section-main{
  margin-bottom: 5vmin;
}

/*end Main*/

