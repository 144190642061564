/* .content-definicion */

.content-definicion {
  padding: 1rem;
  box-sizing: border-box;
}
.content-definicion h2 {
  margin: 0;
  margin-bottom: 1rem;
}
.content-definicion > .main-definicion {
  display: grid;
  grid-template-columns: 1fr 40%;
  padding: 1rem;
  column-gap: 1rem;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.content-definicion > .main-definicion h3 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 16pt;
}

.content-definicion > .main-definicion h4 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 14pt;
}

.content-definicion > .main-definicion .definition-description-content {
  margin-bottom: 1rem;
}

.content-definicion .definition-steps-content {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
}

.content-definicion .definition-steps-content > div {
  padding-left: 0.5rem;
  box-sizing: border-box;
}

.content-definicion > .main-definicion p {
  margin: 0;
}

.content-definicion .main-definicion > .content-left {
  position: relative;
}
.content-definicion .main-definicion > .content-left .fade-feedback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 114, 138, 0.1);
  border-radius: 10px;
}

.content-definicion .main-definicion > .content-left > div:last-child {
  z-index: 100;
}

.content-definicion .content-callback .callback {
  margin: auto;
  display: block;
  padding: 0.7rem 1rem;
  background: rgba(46, 114, 138, 0.8);
  font-family: Montserrat;
  font-size: 11pt;
  border: unset;
  color: white;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
}

.content-definicion .content-breath-count {
  margin-bottom: 1rem;
  text-align: right;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-definicion .content-breath-count > span {
  position: absolute;
  top: 0;
  right: 0;
  display: inline;
  color: white;
  background: rgba(46, 114, 138, 0.8);
  padding: 1rem;
  font-size: 18pt;
  border-radius: 5px;
}

.content-definicion .content-breath-count > span > small {
  font-size: 10pt;
}

.content-breath-indicator {
  width: 200px;
  /* height: 250px; */
  position: relative;
  display: flex;
  flex-direction: column;
}

.content-breath-indicator > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-breath-indicator .breath-time {
  font-size: 30pt;
  line-height: 0.9;
}

.content-breath-indicator .breath-time small {
  font-size: 10pt;
}
.content-breath-indicator .breath-action {
  font-size: 10pt;
}


.content-breath-indicator svg {
  transform: scale(0.7);
  position: relative;
}


.content-breath-indicator svg.breath[act='IN'] {
  animation: BreathIn ease-out forwards;
  transform: scale(0.7);
}
.content-breath-indicator svg.breath[act='OUT'] {
  animation: BreathIn ease-out reverse forwards;
  transform: scale(1);
}
.content-breath-indicator svg.breath[act='AFTER-IN'] {
  transform: scale(1);
}
.content-breath-indicator svg.breath[act='AFTER-OUT'] {
  transform: scale(0.7);
}


.content-breath-indicator svg path {
  transition: 1s d;
}

.content-breath-init{
  position: absolute;
  z-index: 500;
  background-color: rgba(46, 114, 138, 0.5);
  /* background-color: rgba(46, 114, 138, 1); */
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-breath-init > div > select{
  border: unset;
  padding: 6px 5px 6px 7px;
  border-radius: 5px;
}

.content-breath-init > div {
  display: flex;
}

.content-breath-init > div > span{
  
  display: inline;
  background: white;
  color: rgb(46, 114, 138);
  padding: 5px 7px;
  /* font-size: 18pt; */
  border-radius: 5px;
  margin-left: 5px;
}

@keyframes BreathIn {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}
