/* .content-scheduler */
.content-scheduler {
  padding: 1rem;
  box-sizing: border-box;
}

.content-scheduler h2,
.content-scheduler h3,
.content-scheduler h4,
.content-scheduler p {
  margin: 0;
}

.content-scheduler > div.content-main-scheduler {
  display: grid;
  grid-template-columns: 1fr 60%;
  column-gap: 1rem;
}

.content-scheduler div.content-main-scheduler > div {
  padding: 1rem;
  box-sizing: border-box;
}

.content-scheduler div.content-proyects {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  height: fit-content;
}

.content-scheduler div.content-proyects > .item-proyect {
  background-color: #f2f2f2;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.content-scheduler div.content-proyects > .item-proyect h4 {
  width: 100%;
  text-align: left;
}
.content-scheduler div.content-proyects > .item-proyect {
  text-align: right;
}

.content-scheduler div.content-proyects > .item-proyect > span {
  color: rgb(46, 114, 138);
}

.content-scheduler div.content-proyects > hr {
  width: 100%;
}

.content-scheduler div.content-task {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  height: fit-content;
}

.content-scheduler div.content-task > .content-task-title * {
  color: rgb(46, 114, 138);
  /* border-top-color: rgb(46,114,138); */
}

.content-scheduler div.content-task > .item-task {
  background-color: #f2f2f2;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
}
.content-scheduler div.content-task > .item-task > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 14pt;
  color: rgb(46, 114, 138);
}

.content-scheduler div.content-task > .item-task > div > span:first-child {
  font-size: 12pt;
}

.content-scheduler div.content-task > p{
  text-align: center;
}

.content-scheduler div.item-proyect span.fa-remove,
.content-scheduler div.content-task span.fa-remove {
  color: #474857;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
  cursor: pointer;
}

.content-scheduler .content-callback-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.content-scheduler .content-callback-title > *:last-child {
  cursor: pointer;
}

.content-scheduler .content-callback-title > * {
  width: fit-content;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f2f2f2;
  width: 60vmin;
  padding: 1rem 2rem;
  z-index: 2;
}

.modal-content h4 {
  margin-bottom: 1rem;
}

.modal-content > div:not(.input-content) {
  display: grid;
  row-gap: 1rem;
}

.modal-content div.input-content {
  display: flex;
  flex-direction: column;
}

.modal-content div.input-content > label {
  margin-bottom: 5px;
}

.modal-content div.input-content > input {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
}

.modal-content div.input-content > select {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
  box-sizing: border-box;
}

.modal-content div.input-content > textarea {
  border: unset;
  padding: 7px 5px;
  font-size: 12pt;
  box-sizing: border-box;
}

.modal-content div.input-content > input:hover {
  border: unset;
}

.input-content > .content-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.input-content > .content-options > label{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.input-content > .content-options > label > span{
  margin-bottom: 5px;
}
.input-content > .content-options > label > input{
  margin: auto;
}


.modal-content > div:last-child > span {
  margin: auto;
  display: block;
  padding: 0.7rem 1rem;
  background: rgba(46, 114, 138, 0.8);
  font-family: Montserrat;
  font-size: 11pt;
  border: unset;
  color: white;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
}

.modal-fade {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.alert-confirm {
  background-color: rgb(46, 114, 138);
  color: white;
  padding: 1rem;
  width: fit-content;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.alert-confirm p {
  margin-bottom: 1rem;
}

.alert-confirm > div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.content-task-notification{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.content-task-notification > div.item-notification{
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
}
.item-notification.notification-danger{
  background-color: #e65100; 
  color: white;
}
.item-notification.notification-warning{
  background-color: #ffa000; 
  color: white;
}
.item-notification.notification-success{
  background-color: #2d718a; 
  color: white;
}
.content-task-notification > div.item-notification > span{
  position: absolute;
  top: 5px;
  right: 5px;
}