/* content-profile */

.content-profile {
  display: grid;
  grid-template-columns: 1fr 30%;
  padding: 1rem;
  column-gap: 1rem;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

/* .content-profile .content-definition{
    height: calc(100vh - 7vmin - 2.2rem);
    overflow-y: scroll;
} */

.content-profile .content-avatar-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-start; */
    height: fit-content;
}
.content-profile .content-avatar-profile > p{
    align-self: flex-start;
}

.content-profile .content-definition h2 {
  margin: 0px;
}

.content-profile .content-definition h3 {
  font-size: 14pt;
}

.content-profile .content-definition p,
.content-profile .content-definition h3 {
  margin: 0px;
}

.content-profile .content-definition .content-graph > div {
  padding: 1rem;
  box-sizing: border-box;
}

.content-profile .content-skills > h2{
    margin-bottom: 0.7rem;
}

.content-powers {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
}

.content-powers > div {
  padding: 1rem;
  background-color: #f2f2f2;
  box-sizing: border-box;
}
.content-profile .content-powers > div h3 {
  margin-bottom: 0.7rem;
}

/* .content-powers{ */
